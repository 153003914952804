import DeviceWidthCheckerMixin from "@/mixins/deviceWidthChecker.mixin";
import { Component, Inject, Mixins } from "vue-property-decorator";
import CmsCardCarousel from "@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue";
import CmsTagComponent from "@/commoncomponents/CmsTagComponent/CmsTagComponent.vue";
import {
  getVideoContentGraphQLQuery,
  getRelatedVideoContentfulGraphQLQuery
} from "@/utilities/contentfulGraphQLHelpers";

import axios from "axios";
import {
  ContentType,
  VideoContentData,
  ContentCardData,
  getCopyrightMessage,
  relatedContentToCardData,
  gqlToLabelData
} from "@/utilities/cmsUtilities";
import BouncingPreloaderComponent from "@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue";
import APP_CONST from "@/constants/AppConst";
import CmsContentfulComponent from "@/commoncomponents/CmsContentfulComponent/CmsContentfulComponent.vue";
import CmsNotAuthorizedComponent from "@/commoncomponents/CmsNotAuthorizedComponent/CmsNotAuthorizedComponent.vue";
import CmsLabelComponent from "@/commoncomponents/CmsLabelComponent/CmsLabelComponent.vue";
import { LabelData } from "@/commoncomponents/CmsLabelComponent/CmsLabelComponent";
import VideoPlayer from "@/commoncomponents/VideoPlayer/VideoPlayer.vue";
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';

@Component({
  components: {
    "cms-tag-list": CmsTagComponent,
    "card-carousel": CmsCardCarousel,
    "cms-contentful-render": CmsContentfulComponent,
    "bouncing-preloader": BouncingPreloaderComponent,
    "cms-content-label": CmsLabelComponent,
    "cms-not-authorized": CmsNotAuthorizedComponent,
    "video-player": VideoPlayer
  }
})
export default class CourseLandingPage extends Mixins(DeviceWidthCheckerMixin) {
  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

  labelData: Array<LabelData> = [];
  relatedContent: Array<ContentCardData> = [];
  loading: boolean = true;
  isAuthorized: boolean = true;
  videoContentId: string = "";
  videoData: VideoContentData = {
    itemTitle: "",
    itemShortDescription: "",
    itemDescription: "",
    completionTimeMinutes: 0,
    completionTimeSeconds: 0,
    grades: [],
    tags: []
  };

  getCopyrightMessage = getCopyrightMessage;

  async fetchData() {
    this.loading = true;
    const url = `${APP_CONST.CONTENTFUL_GRAPHQL_URL}${APP_CONST.CONTENTFUL_GRAPHQL_SPACE}${APP_CONST.CONTENTFUL_GRAPHQL_ENV}`;
    const headers = {
      Authorization: `Bearer ${APP_CONST.CONTENTFUL_GRAPHQL_TOKEN}`
    };
    const method = "post";
    const content = await axios({
      url: url,
      headers: headers,
      method: method,
      data: {
        query: getVideoContentGraphQLQuery(this.videoContentId)
      }
    });

    const relatedContentResponse = await axios({
      url: url,
      headers: headers,
      method: method,
      data: {
        query: getRelatedVideoContentfulGraphQLQuery(
          "mediaResourceVideo",
          this.videoContentId
        )
      }
    });

    this.videoData = content.data.data.mediaResourceVideo;
    if (
      relatedContentResponse.data.data.mediaResourceVideo &&
      relatedContentResponse.data.data.mediaResourceVideo
        .relatedContentCollection &&
      relatedContentResponse.data.data.mediaResourceVideo
        .relatedContentCollection.items.length > 0
    ) {
      this.relatedContent = relatedContentToCardData(
        relatedContentResponse.data.data.mediaResourceVideo
          .relatedContentCollection.items
      );
    }
    this.videoData.completionTimeMinutes = this.videoData.completionTimeMinutes
      ? this.videoData.completionTimeMinutes
      : 0;

    this.videoData.completionTimeSeconds = this.videoData.completionTimeSeconds
      ? this.videoData.completionTimeSeconds
      : 0;
    this.labelData = gqlToLabelData({
      type: ContentType.VIDEO,
      videoTime: `${this.videoData.completionTimeMinutes} min : ${this.videoData.completionTimeSeconds} sec`,
      grades: this.videoData.grades
    });
    this.loading = false;
  }

  async beforeMount() {
    const id = this.$route.query.id;
    if (id) {
      this.videoContentId = id.toString();
    }
    await this.fetchData();
  }

  onVideoPlay(): void {
    this.analyticsService.track(analyticsEventNames.LEARNING_VIDEO_RESOURCE_PLAYED);
  }
}
