
















































import CourseLandingPage from "./VideoResourcePage";
export default CourseLandingPage;
